import { useState } from '#app'
import type {
  ExtendedAssetType,
  OrderRequestParams,
  OrderRequestItem,
} from '@/types/interfaces'
import { assetAttributes } from '@/utils/useVehicleFormVars'

export const useOrderRequestParams = () => {
  const orderRequestParams = {
    address: '',
    company: '',
    quantityDiscount: 0,
    durationDiscount: 0,
    duration: 0,
    firstName: '',
    isMarketingOptIn: false,
    isPrivacyConfirmed: false,
    lastName: '',
    mail: '',
    voucher: '',
    note: '',
    phone: '',
    startingDate: '',
    endDate: '',
    items: [],
  } as OrderRequestParams

  const requestData = useState('orderRequestParams', () => orderRequestParams)

  const toggleAssetInItemList = (assetType: ExtendedAssetType) => {
    const existingAssetIndex = _findIndexForAssetTypeInItemList(assetType)

    if (existingAssetIndex == -1) {
      _addAssetTypeToItemList(assetType)
    } else {
      requestData.value.items.splice(existingAssetIndex, 1)
    }
  }

  const addAssetTypeToItemList = (assetType: ExtendedAssetType) => {
    if (_findIndexForAssetTypeInItemList(assetType) == -1) {
      _addAssetTypeToItemList(assetType)
    }
  }

  function _findIndexForAssetTypeInItemList(assetType: ExtendedAssetType) {
    return requestData.value.items.findIndex((item) => {
      return item.assetType.id == assetType.id
    })
  }

  function _addAssetTypeToItemList(assetType: ExtendedAssetType) {
    let equipementOptions = assetAttributes()[assetType.category]
    let equipement = []

    if (assetType.category == 'semi_trailer') {
      equipementOptions = equipementOptions[assetType.slug]
    }

    equipementOptions.forEach((option: string) => {
      equipement.push({ option: option, value: false })
    })

    requestData.value.items.push({
      amount: 1,
      assetType: assetType,
      requirements: {
        manufacturer: '',
        equipement: equipement,
      },
    } as OrderRequestItem)
  }

  return {
    requestData,
    toggleAssetInItemList,
    addAssetTypeToItemList,
  }
}

export const useDiscountPercentage = () => {
  const { requestData } = useOrderRequestParams()
  return requestData.value.durationDiscount + requestData.value.quantityDiscount
}

export const useSelectedAssetsCount = () => {
  const { requestData } = useOrderRequestParams()

  return requestData.value.items.reduce(
    (previousValue, currentItem) => previousValue + (currentItem.amount || 0),
    0
  )
}
